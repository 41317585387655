import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { getCurrentInstance, reactive, toRefs, watch } from '@vue/composition-api'
import moment from 'moment'

// eslint-disable-next-line consistent-return
export const isNumber = evt => {
  // eslint-disable-next-line no-param-reassign
  evt = evt || window.event
  const charCode = (evt.which) ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    evt.preventDefault()
  } else {
    return true
  }
}

export const getPayPeriods = () => {
  moment.locale('nl')
  return [
    {
      label: moment().format('MMMM YYYY'),
      value: moment().format('YYYY-MM'),
    },
    {
      label: moment().subtract(1, 'months').format('MMMM YYYY'),
      value: moment().subtract(1, 'months').format('YYYY-MM'),
    },
    {
      label: moment().subtract(2, 'months').format('MMMM YYYY'),
      value: moment().subtract(2, 'months').format('YYYY-MM'),
    },
  ]
}

export const getFullName = data => {
  let fullName = ''

  if (data) {
    if (data.middleName && data.middleName.length > 0) {
      fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
    } else {
      fullName = `${data.firstName} ${data.lastName}`
    }
  }

  return fullName
}

export const formatDate = date => moment(date)
  .format('DD-MM-YYYY')

export const formatDateTime = date => {
  if (!date || date === '') {
    return ''
  }

  return moment(date)
    .format('DD-MM-YYYY HH:MM:ss')
}

export const formatYearMothString = value => {
  if (!value || value === '') {
    return ''
  }

  const splittedValue = value.split('-')
  return `${splittedValue[1]}-${splittedValue[0]}`
}

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return {
    ...toRefs(state),
    router: vm.$router,
  }
}

export const generatePassword = () => {
  let CharacterSet = ''
  let password = ''
  const size = 12
  const charactersArray = 'a-z,A-Z,0-9,#'.split(',')

  if (charactersArray.indexOf('a-z') >= 0) {
    CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
  }
  if (charactersArray.indexOf('A-Z') >= 0) {
    CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  }

  if (charactersArray.indexOf('0-9') >= 0) {
    CharacterSet += '0123456789'
  }
  if (charactersArray.indexOf('#') >= 0) {
    CharacterSet += '![]{}()%&*$#<>@|+=?S'
  }
  for (let i = 0; i < size; i += 1) {
    password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
  }

  return password
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }
